import { initializeSentry } from 'clients/sentry'
import initDatadogRum from './datadog_rum'
import { loadFeature } from 'vue_features/dynamic_features'
import onReadyLzuiBootstrap from './lzui_bootstrap'
import onReadyToggleDropdown from './toggle_dropdowns'
import { buildVueApp } from './create_vue_features'
import onReadyResponsiveNavigation from './responsive_navigation'
import onReadyDebug from './debug'
import debug from 'debug'

let jsonData = null

const log = debug('app:initializers/vue_features')

function onReadyCreateVueFeatures() {
  const features = []
  jsonData = jsonData || JSON.parse(document.getElementById('vue-data').textContent)

  const promises = [...document.querySelectorAll('[data-vue-feature]')].map(async (parent) => {
    const { name, state = {}, attrs = {}, props = {} } = jsonData[parent.dataset.vueJsonIndex]
    log('loading', name)
    const { default: initFeature } = await loadFeature(name)
    if (!initFeature) {
      throw new Error(
        `${name} not found in vue_features/index.js. Did you spell it correctly? (i.e. HelloWorldFeature)`,
      )
    }
    const placeholders = Array.from(parent.childNodes)

    log('initializing', name)
    const { component, messages, options, router, props: featureProps } = initFeature({ state, props })

    // root_store_setter_feature does not build a component
    if (component) {
      features.push({
        app: buildVueApp({ component, messages, options, router, props: featureProps || props, attrs }, false),
        el: parent,
      })
    }

    placeholders.forEach((ele) => ele.remove())
  })

  // Execute after all features are initialized
  Promise.all(promises).then(() => {
    log('all initialized!')
    initializeSentry(features.map((component) => component.app))
    features.forEach((component) => component.app.mount(component.el))
  })
}

initDatadogRum()

export function onReadyApplication() {
  onReadyLzuiBootstrap()
  onReadyToggleDropdown()
  onReadyResponsiveNavigation()
  onReadyCreateVueFeatures()
  onReadyDebug()
}
