export default function addSafariStyling() {
  const element = document.createElement('style')
  document.head.appendChild(element)

  const sheet = element.sheet
  let pageContentFix = '.page-content {'
  pageContentFix += 'height: 9.5in !important;\n'
  pageContentFix += '}'
  let pageFix = '.page {'
  pageFix += 'height: 10.3in !important;'
  pageFix += '}'

  sheet.insertRule(pageContentFix, 0)
  sheet.insertRule(pageFix, 1)
}
